import loginPageIconImport from "../assets/login.svg";
import registerPageIconImport from "../assets/register.svg";
import storeRegisterPageIconImport from "../assets/storeRegisterIcon.svg";

export const adminPath = "mo-salah-admin";
export const outletPath = "mo-salah-outlet";
export const domainName = "salah-store.com";

export const isManualPayment = false;
export const isCouponable = false;
export const isMultiYear = true;
export const isCodes = true;
export const isBtns = true;
export const isLoginTokens = false;
export const profileStatistics = false;

export const isMultiProduct = true;
export const isStore = true;
export const isMultiSellers = true;
export const isAbleChangingAdress = true;

export const enableAutoLogin = false;
export const isPinnedCourse = false;
export const isSubscribeBySubjectTeacher = false;

export const adminPanelColor = "yellow";
export const headerSectionColor = "yellow";
export const navbarIconColor = "text-yellow-500";
export const navbarDisclosureColor = "yellow";

export const progressBarColor = "moGreenV2";
export const loadingBarColor = "moGreenV2";

export const isPlatformSubscription = false;
export const isTimedManualSubscription = false;

export const isPrepaidSystem = false;
export const prepaidInfoColor = "yellow";

export const autoChangeRandomNames = false;

export const navThemeRTL = true;

export const registerPageIcon = registerPageIconImport;
export const loginPageIcon = loginPageIconImport;
export const storeRegisterPageIcon = storeRegisterPageIconImport;

export const loginPageColor = "yellow";
export const registerPageColor = "moGreenV2";

export const isAnalytics = false;
export const isAbleChangingPassword = false;

export const isMultiAdmin = true;

export const isCategories = false;

export const isSubjectSubscriptionable = true;

export const isAccountCreationRequest = true;

export const showExamStatisticsTable = false;

export const isOtpEnabled = false;

export const isWalletEnabled = false;

export const isOnlySubscribeFromWallet = false;

export const isCenterUsers = false;

export const isForgetPassword = false;

export const isGlobalNotifications = true;
export const isGlobalNotificationsByProducts = false;

export const isAdminUsers = false;
export const isAdsEnable = false;

export const isMigrationFromInsertAuto = false;
export const isChargeInsertAuto = false;
export const isInsertAutoTypes = false;

export const isCourseRenamedToMonthlySubscriptions = false;

export const isCommunity = false;

export const isCustomUserExamLimit = false;

export const isCenterApi = false;

export const isEssayQuestions = false;

export const isAdminCourseStatistics = false;

export const isSearch = false;

export const isWinterComing = false;

export const isOrderInsertAuto = true;
