import React, { useEffect, useState } from "react";
import HeaderCardsSection from "../../components/ui/HeaderCardsSection";
import Container from "../../components/ui/Container";
import AddressEdit from "./AddressEdit";
import auth from "../../services/authServices";
import http from "../../services/httpServices";
import { Icon } from "@iconify/react/dist/iconify.js";
import RemoteTable from "../../components/ui/RemoteTable";
import { printFullDate } from "../../utils/time";
import { printUnit } from "../../utils/ar";
import Button from "../../components/ui/Button";
import modal from "../../services/modalServices";

const MyCodes = () => {
    let columns = [
        // {
        //     name: "تسلسل الكود",
        //     reorder: true,
        //     selector: (row) => row.id,
        //     sortable: true,
        //     sortField: "id",
        // },
        {
            name: "الكود الخاص بك",
            reorder: true,
            selector: (row) => row.insert_auto_phone,
            sortable: true,
        },
        {
            name: "تسلسل الأوردر",
            reorder: true,
            selector: (row) => row.order_id,
        },
        {
            name: "تمن الأوردر المرتبط بالكود",
            reorder: true,
            selector: (row) => (row.order ? printUnit(row.order.total_price, "جنيه") : "---"),
        },
        {
            name: "عايز تستفيد من الكود؟",
            reorder: true,
            selector: (row) => (
                <Button
                    onClick={() => {
                        modal.message({
                            title: "ركز معايا كويس ؟",
                            text: "لازم تكون متأكد انك هتستفيد من الكود وهتستخدمه في الأكونت الخاص بيك علي منصة بسطتهالك وفي حالة معندكش حساب انشئ حسابك وهيتقبل عالطول",
                            icon: "info",
                            buttons: {
                                confirm: "روح دلوقتي",
                                cancel: "مش عايز",
                            },
                            callback: async (e) => {
                                if (e && e !== "cancel") {
                                    window.open(
                                        "https://bassthalk.com/me/user/charge_insert_auto?code=" +
                                            row.insert_auto_phone,
                                        "_blank"
                                    );
                                }
                            },
                        });
                    }}
                    color="moGreenV2"
                >
                    <span className="text-butterMilk-50">وديني اشحن الكود</span>
                </Button>
            ),
        },
        {
            name: "وقت الحصول علي الكود",
            reorder: true,
            selector: (row) => printFullDate(row.updated_at),
        },
    ];
    return (
        <>
            <div className="flex flex-col space-y-10">
                <section className="flex flex-col flex-center-both space-y-10 px-10">
                    <HeaderCardsSection
                        title="أكوادي"
                        coloredTitle="الخاصة"
                        colorClass="text-goBlue-600"
                    />
                    <p className="w-full md:w-2/3  text-md text-center text-slate-700 dark:text-slate-100">
                        بص يا صديقي : هنا هتلاقي كل الأكواد الخاصة بيك الي بتحصل عليها من خلال شرائك
                        بعض المنتجات الي بيكون موضح فيها انها مرفقه بكود وتقدر تستخدمها في الأكونت
                        الخاص بيك بمنصة بسطتهالك
                    </p>
                </section>
                <div className="w-full font-alm">
                    <RemoteTable
                        isAdmin={false}
                        api={`/api/user/get-my-codes/paginate`}
                        columns={columns}
                    />
                </div>
            </div>
        </>
    );
};

export default MyCodes;
