import React, { useContext, useEffect } from "react";
import Container from "../components/ui/Container";
import HeaderSection from "../components/ui/HeaderSection";
import LoadingIcon from "../components/ui/LoadingIcon";
import AuthContext from "../context/AuthContext";
import { isObjectEmpty } from "../utils/objects";
import { Outlet, NavLink } from "react-router-dom";
import AnimatingPage from "../middleware/AnimatingPage";
import CenterIcon from "../components/ui/CenterIcon";
import {
    isAbleChangingPassword,
    isLoginTokens,
    isOrderInsertAuto,
    isWinterComing,
} from "../services/defaultSettings";
import page from "../services/pageServices";
import { Icon } from "@iconify/react";
import Snowfall from "../components/ui/Snowfall";

const User = () => {
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const currentTitle = page.getCurrentTitle();
        page.setTitle("ملف المستخدم");
        return () => {
            page.setTitle(currentTitle);
        };
    }, []);

    return (
        <>
            {isWinterComing && <Snowfall />}

            <div className="bg-outer-container font-ge smooth clr-text-primary negative-nav-margin posisitve-nav-padding-top">
                <Container className="py-10 pb-10 space-y-0">
                    <HeaderSection></HeaderSection>
                    {isObjectEmpty(user) && (
                        <AnimatingPage>
                            <div className="relative z-10 w-full">
                                <div className="w-full flex-center-both flex-col">
                                    <div className="bg-inner-container clr-text-primary shadow-large rounded-md smooth px-8 py-10 w-full max-w-lg -mt-16 ">
                                        <span className="flex-center-both space-x-3 space-x-reverse">
                                            <LoadingIcon
                                                className={
                                                    "font-big text-teal-500 dark:text-teal-300"
                                                }
                                            />
                                            <span>يتم الآن تحميل بياناتك.....</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </AnimatingPage>
                    )}
                    {!isObjectEmpty(user) && (
                        <AnimatingPage>
                            <Container>
                                <Container>
                                    <div className=" bg-moGreen-950/75 px-5 py-10  rounded-md shadow-lg -mt-32 relative z-10 smooth border-2 border-slate-500 border-opacity-10">
                                        <div className="flex-center-both"></div>
                                        <div className="flex flex-col sm:flex-row pt-5">
                                            <div className="sm:basis-1/4 shrink-0 min-w-0 pb-5 flex">
                                                <div className="relative w-full">
                                                    <div className="px-4 py-4  flex flex-col  w-full space-y-3">
                                                        <NavLink
                                                            to="./"
                                                            className={({ isActive }) =>
                                                                isActive
                                                                    ? "flex  items-center gap-2 py-2 px-3 rounded-md shadow-md border-2 clr-text-primary bg-secBlue-800 clr-white  border-blue-100 border-opacity-20 smooth"
                                                                    : " flex items-center gap-2 py-2 px-3 rounded-md shadow-md border-2 clr-text-primary bg-primary-container border-slate-500 border-opacity-10 smooth hover:bg-opacity-0 hover:border-blue-500 hover:text-blue-500"
                                                            }
                                                        >
                                                            <Icon icon="noto:student-light-skin-tone" />
                                                            ملف المٌشتري
                                                        </NavLink>
                                                        <NavLink
                                                            to="./my-orders"
                                                            className={({ isActive }) =>
                                                                isActive
                                                                    ? "flex  items-center gap-2 py-2 px-3 rounded-md shadow-md border-2 clr-text-primary bg-secBlue-800 clr-white  border-blue-100 border-opacity-20 smooth"
                                                                    : " flex  items-center gap-2 py-2 px-3 rounded-md shadow-md border-2 clr-text-primary bg-primary-container border-slate-500 border-opacity-10 smooth hover:bg-opacity-0 hover:border-blue-500 hover:text-blue-500"
                                                            }
                                                        >
                                                            <Icon icon="noto-v1:books" />
                                                            أوردراتي
                                                        </NavLink>
                                                        {isOrderInsertAuto ? (
                                                            <NavLink
                                                                to="./codes"
                                                                className={({ isActive }) =>
                                                                    isActive
                                                                        ? "flex  items-center gap-2 py-2 px-3 rounded-md shadow-md border-2 clr-text-primary bg-secBlue-800 clr-white  border-blue-100 border-opacity-20 smooth"
                                                                        : "flex  items-center gap-2 py-2 px-3 rounded-md shadow-md border-2 clr-text-primary bg-primary-container border-slate-500 border-opacity-10 smooth hover:bg-opacity-0 hover:border-blue-500 hover:text-blue-500"
                                                                }
                                                            >
                                                                <Icon icon="openmoji:location-indicator-red" />
                                                                أكوادي
                                                            </NavLink>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <NavLink
                                                            to="./my-address"
                                                            className={({ isActive }) =>
                                                                isActive
                                                                    ? "flex  items-center gap-2 py-2 px-3 rounded-md shadow-md border-2 clr-text-primary bg-secBlue-800 clr-white  border-blue-100 border-opacity-20 smooth"
                                                                    : "flex  items-center gap-2 py-2 px-3 rounded-md shadow-md border-2 clr-text-primary bg-primary-container border-slate-500 border-opacity-10 smooth hover:bg-opacity-0 hover:border-blue-500 hover:text-blue-500"
                                                            }
                                                        >
                                                            <Icon icon="openmoji:location-indicator-red" />
                                                            بيانات الإستلام
                                                        </NavLink>
                                                        {isAbleChangingPassword ? (
                                                            <NavLink
                                                                to="./change_password"
                                                                className={({ isActive }) =>
                                                                    isActive
                                                                        ? "py-2 px-3 rounded-md shadow-md border-2 clr-text-primary bg-secBlue-800 clr-white  border-blue-100 border-opacity-20 smooth"
                                                                        : "py-2 px-3 rounded-md shadow-md border-2 clr-text-primary bg-primary-container border-slate-500 border-opacity-10 smooth hover:bg-opacity-0 hover:border-blue-500 hover:text-blue-500"
                                                                }
                                                            >
                                                                تغيير كلمة المرور
                                                            </NavLink>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sm:basis-3/4 flex flex-col py-5 w-full  overflow-auto">
                                                <Outlet />
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            </Container>
                        </AnimatingPage>
                    )}
                </Container>
            </div>
        </>
    );
};

export default User;
