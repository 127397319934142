import React from "react";

import Swal from "sweetalert2";

// import Button from "./Button";
import { printUnit } from "../../utils/ar";
import Button from "./Button";
import Container from "./Container";
import { printFullDateTime } from "../../utils/time";
import payment from "../../services/paymentServices";
import { Icon } from "@iconify/react/dist/iconify.js";
import { isOrderInsertAuto } from "../../services/defaultSettings";
import modal2 from "../../services/modal2Services";

const OrderCard = ({
    total_price = "",
    created_at,
    payment_time,
    id,
    customer_delivery_price,
    invoice_id = "",
    invoice_key = "",
    invoice_status = "pending",
    invoice_provider,
    delivery_status = "pending",
    products = [],
    insert_auto_codes = [],
    ...props
}) => {
    const showInsertCodes = () => {
        if (!insert_auto_codes.length) return;

        let htmlContent = `
            <table style="width:100%; border-collapse: collapse; text-align: center;">
                <thead>
                    <tr style="background:#f2f2f2;">
                        <th style="padding: 10px; border: 1px solid #ddd;">عنوان الكود</th>
                        <th style="padding: 10px; border: 1px solid #ddd;">الكود</th>
                    </tr>
                </thead>
                <tbody>
                    ${insert_auto_codes
                        .map(
                            (code) => `
                            <tr>
                                <td style="padding: 10px; border: 1px solid #ddd;">${code.insert_auto_name}</td>
                                <td style="padding: 10px; border: 1px solid #ddd;">${code.insert_auto_phone}</td>
                            </tr>
                        `
                        )
                        .join("")}
                </tbody>
            </table>
        `;

        modal2.message({
            title: "أكوادك الخاصة",
            html: htmlContent,
            icon: "info",
            confirmButtonText: "تم",
            customClass: {
                confirmButton: "bg-blue-500 text-white px-4 py-2 rounded-lg",
            },
        });
    };

    return (
        <div className="group  shadow-md rounded-lg bg-butterMilk-50 dark:bg-moGreen-200">
            <Container className=" px-1 sm:px-4  ">
                <div className="flex flex-center-both  space-y-5 flex-col">
                    <div className="text-moGreenV2-700 flex-center-both font-bold text-lg">
                        {printFullDateTime(created_at)}
                    </div>
                    <div className="divider-1  bg-slate-600" />
                </div>
                <div className="flex flex-col space-y-5">
                    <div className="flex gap-1 sm:gap-2 text-sm md:text-lg items-center">
                        <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                            المنتجات :
                        </p>
                        {/* products names here */}
                        {products.map((product, index) => (
                            <span key={index} className="text-gray-600">
                                {product.quantity} : {product.name}
                            </span>
                        ))}
                    </div>
                    <div className="flex gap-1 font-ge sm:gap-2 text-sm md:text-lg items-center">
                        <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                            السعر الكلي :
                        </p>
                        {/* order total price here */}
                        {printUnit(total_price, "جنيه")}
                    </div>
                    <div className="flex gap-1 sm:gap-2 text-sm md:text-lg items-center">
                        <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                            سعر الشحن :
                        </p>
                        {/* products names here */}
                        {printUnit(customer_delivery_price, "جنيه")}
                    </div>
                    <div className="flex gap-1 sm:gap-2 text-sm md:text-lg items-center">
                        <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                            لينك الفاتورة :
                        </p>
                        {/* products names here */}
                        <Button
                            className="text-sm md:text-lg flex flex-center-both leading-3 py-0 md:py-2 px-2 md:px-4"
                            element="a"
                            color="moGreenV2"
                            href={payment.getInvoiceUrl({
                                invoice_id,
                                invoice_key,
                                invoice_provider,
                            })}
                        >
                            هنا
                        </Button>
                    </div>
                    <div className="flex gap-1 sm:gap-2 text-sm md:text-lg items-center">
                        <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                            حالة الدفع :
                        </p>
                        {/* products names here */}
                        {invoice_status === "pending" ? (
                            <span className="py-1 px-2 bg-rose-600 text-white rounded-md">
                                لم يتم الدفع
                            </span>
                        ) : (
                            <span className="py-1 px-2 bg-emerald-600 text-white rounded-md">
                                {invoice_status}
                            </span>
                        )}
                    </div>
                    {invoice_status === "paid" ? (
                        <div className="flex gap-1 sm:gap-2 text-sm md:text-lg items-center">
                            <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                                وقت الدفع :
                            </p>
                            <span className="">{printFullDateTime(payment_time)}</span>
                        </div>
                    ) : (
                        ""
                    )}

                    <div className="flex gap-2 text-sm md:text-lg items-center">
                        <p className="text-secBlue-100 dark:text-secBlue-900 font-bold">
                            حالة التوصيل :
                        </p>
                        {/* products names here */}
                        {delivery_status === "pending" ? (
                            <span className="py-1 px-2 bg-rose-600 text-white rounded-md">
                                {delivery_status}
                            </span>
                        ) : (
                            <span className="py-1 px-2 bg-emerald-600 text-white rounded-md">
                                {delivery_status}
                            </span>
                        )}
                    </div>
                    {isOrderInsertAuto && insert_auto_codes.length > 0 && (
                        <div className="flex gap-2 justify-between items-center bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-200 px-3 py-2 rounded-md">
                            <div className="flex space-x-1 space-x-reverse">
                                <Icon icon="mdi:key-outline" className="text-2xl" />
                                <span className="font-bold">لديك أكواد خاصة!</span>
                            </div>
                            <Button
                                className="text-sm px-3 py-1 bg-blue-600 text-white rounded-lg"
                                onClick={showInsertCodes}
                            >
                                عرض الأكواد
                            </Button>
                        </div>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default OrderCard;
